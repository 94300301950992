import styled from 'styled-components'
import Container from './Container'

const Page = styled(Container)`
  padding: 16px;
  max-width: 1536px;
  margin: auto;
`

export default Page
