import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'BNB',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
    },
    tokenSymbol: 'BNB',
    priceSymbol: "KAVABNB",
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
    },
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Wrapped Kava',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    },
    tokenSymbol: 'WKAVA',
    priceSymbol: "WKAVA",
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    },
    quoteTokenSymbol: QuoteToken.WKAWA,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    },
  },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USD Coin',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    },
    tokenSymbol: 'USDC',
    priceSymbol: "USDCTUSD",
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    },
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USDT Coin',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    },
    tokenSymbol: 'USDT',
    priceSymbol: "KAVAUSDT",
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    },
  },
]

export default farms
