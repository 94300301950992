import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from 'contexts/ThemeContext'

const useTheme = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext)
  const theme = useContext(StyledThemeCopntext)

  const primaryColor = '#0c3'
  const hoverColor = primaryColor

  const bgColor = '#2a2a2a'
  const elementBgColor = '#202020'

  const textColor = '#ffffff'
  const btnTextColor = '#202020'

  theme.colors.text = textColor
  theme.colors.secondary = primaryColor
  theme.colors.textSubtle = primaryColor
  theme.colors.overlay = primaryColor
  theme.colors.primary = primaryColor
  theme.colors.primaryBright = primaryColor
  theme.colors.background = bgColor
  theme.colors.borderColor = `rgba(0,0,0,0.5)`
  theme.colors.input = `rgba(0,0,0,0.5)`
  theme.colors.overlay = `rgba(0,0,0,0.9)`

  theme.card.background = elementBgColor
  theme.card.boxShadow = '0 0 30px 0 rgba(0, 0, 0, 0.3)'

  theme.shadows.focus = primaryColor

  theme.button.primary.background = primaryColor
  theme.button.primary.color = btnTextColor
  theme.button.primary.backgroundActive = hoverColor
  theme.button.primary.backgroundHover = hoverColor
  theme.button.primary.boxShadow = 'none'
  theme.button.primary.boxShadowActive = 'none'

  theme.button.secondary.background = primaryColor
  theme.button.secondary.color = btnTextColor
  theme.button.secondary.backgroundActive = hoverColor
  theme.button.secondary.backgroundHover = hoverColor
  theme.button.secondary.boxShadow = 'none'
  theme.button.secondary.boxShadowActive = 'none'

  theme.button.tertiary.background = primaryColor
  theme.button.tertiary.color = btnTextColor
  theme.button.tertiary.backgroundActive = hoverColor
  theme.button.tertiary.backgroundHover = hoverColor
  theme.button.tertiary.boxShadow = 'none'
  theme.button.tertiary.boxShadowActive = 'none'

  theme.button.subtle.background = primaryColor
  theme.button.subtle.color = btnTextColor
  theme.button.subtle.backgroundActive = hoverColor
  theme.button.subtle.backgroundHover = hoverColor
  theme.button.subtle.boxShadow = 'none'
  theme.button.subtle.boxShadowActive = 'none'

  theme.button.text.color = primaryColor

  theme.modal.background = elementBgColor

  return { isDark, toggleTheme, theme }
}

export default useTheme
