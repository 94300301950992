import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'
import './style.css'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  /* poppins-100 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/poppins-v20-latin-100.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-100.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-200 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/poppins-v20-latin-200.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-200.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-300 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-800.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/poppins-v20-latin-900.eot'); /* IE9 Compat Modes */
    src: url('/fonts/poppins-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/poppins-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/poppins-v20-latin-900.woff') format('woff'), /* Modern Browsers */
         url('/fonts/poppins-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/poppins-v20-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  * {
    font-family: 'Poppins', sans-serif;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    button {
      border-radius: 0.4rem !important;
    }

    button:disabled {
      background-color: rgba(255,255,255,0.04) !important;
      color: rgba(255,255,255,0.25) !important;
      border: 2px solid rgba(255,255,255,0.25) !important;
    }

    button#wallet-connect-metamask,
    button#wallet-connect-trustwallet {
      div {
        color: #fff;
      }
    }
  }

`

export default GlobalStyle
